import { Helmet } from "react-helmet-async"

function Head (props) {
  const { metadata } = props

  // const { favicon, title, author, description, image } = metadata
  const { title, author, description, image } = metadata

  return (
    <Helmet>
      { title
      ? <>
          <title>{title}</title>
          <meta name="title" content={title} />
        </>
      : null
      }
      { author ? <meta name="author" content={author} /> : null }
      { description ? <meta name="description" content={description} /> : null }
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.origin + window.location.pathname} />
      { title ? <meta property="og:title" content={title} /> : null }
      { description ? <meta property="og:description" content={description} /> : null }
      { image?.url ? <meta property="og:image" content={image.url} /> : null }
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.origin + window.location.pathname} />
      { title ? <meta property="twitter:title" content={title} /> : null }
      { description ? <meta property="twitter:description" content={description} /> : null }
      { image?.url ? <meta property="twitter:image" content={image.url} /> : null }
      <link rel="canonical" href={window.location.origin + window.location.pathname} />
    </Helmet>
  )
}

export default Head
