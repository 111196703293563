import { useState, useCallback, useEffect } from "react"
import { NavLink } from "react-router-dom"

const COLOUR_CLASSES = ["color-dark-salmon", "color-dark-fawn", "color-dark-cambridge-blue", "color-dark-cool-gray"]

function Nav (props) {
  const { pages } = props
  const [ activePage, setActivePage ] = useState(null)

  const handleClick = useCallback(e => {
    setActivePage(e.currentTarget)
  }, [])

  useEffect(() => {
    const navLinks = Array.from(document.querySelectorAll("header a"))
    navLinks.forEach(link => {
      link.addEventListener("click", handleClick)
    })
    return () => {
      navLinks.forEach(link => {
        link.removeEventListener("click", handleClick)
      })
    }
  }, [handleClick])

  useEffect(() => {
    if (activePage) {
      window.scrollTo(0, 0)
      activePage.blur()
      activePage.tabIndex = -1
      const navLinks = Array.from(document.querySelectorAll("header a"))
      navLinks.filter(link => link !== activePage).forEach(link => {
        link.removeAttribute("tabindex")
      })
    }
  }, [activePage])

  useEffect(() => {
    setActivePage(document.querySelector("header a.active"))
  }, [])

  return (
    <nav className="main-nav">
      <ul>
        { pages?.map((page, index) =>
          <li key={index}>
            <NavLink to={`/${page.slug}`} className={COLOUR_CLASSES[index % COLOUR_CLASSES.length]}>{page.title}</NavLink>
          </li>
        ) }
      </ul>
    </nav>
  )
}

export default Nav
