import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import "./Header.css"
import HamburgerMenu from "./HamburgerMenu"
import Nav from "./Nav"
import Image from "../components/Image"

function Header (props) {
  const { author, logo, socials, pages, viewMode } = props

  useEffect(() => {
    const header = document.getElementsByTagName("header")[0]
    if (header) {
      const observer = new IntersectionObserver(entries => {
        const menu = document.getElementById("mobile-menu")
        const isOpen = menu?.classList.contains("is-open")
        if (!entries[0].isIntersecting && !isOpen) {
          header.classList.add("small")
        } else {
          header.classList.remove("small")
        }
      }, { threshold: 0 })
      if (["phone", "tablet-portrait", "tablet-landscape", "small-desktop"].includes(viewMode)) {
        const pageTop = document.getElementById("page-top")
        observer.observe(pageTop)
      }
      return () => observer.disconnect()
    }
  }, [viewMode])

  return (
    <>
      { ["phone", "tablet-portrait"].includes(viewMode)
      ? <header className="mobile">
          <NavLink to="/">
            <Image className="logo" id={logo?.image.id} preview={logo?.image.preview} alt={logo?.image.alt} />
            <span className="sr-only">Home</span>
          </NavLink>
          <HamburgerMenu author={author} socials={socials} pages={pages} />
        </header>
      : <header>
          <NavLink to="/">
            <Image className="logo" id={logo?.image.id} preview={logo?.image.preview} alt={logo?.image.alt} />
            <span className="sr-only">Home</span>
          </NavLink>
          <Nav pages={pages} />
        </header>
      }
    </>
  )
}

export default Header
