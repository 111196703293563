import "./Footer.css"
import Socials from "./Socials"
import Copyright from "./Copyright"

function Footer (props) {
  const { author, socials } = props

  return (
    <footer>
      <Socials data={socials} />
      <Copyright author={author} />
    </footer>
  )
}

export default Footer
