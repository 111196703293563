const year = new Date().getFullYear()

function Copyright (props) {
  const { author } = props

  return (
    <p className="copyright">Copyright &copy; { year } { author }</p>
  )
}

export default Copyright
