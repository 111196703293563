import { useState } from "react"
import "./ContactForm.css"

async function handleSubmit (e, setSubmitText) {
  e.preventDefault()
  setSubmitText("Sending...")
  const formElements = [...e.currentTarget.elements]
  const isValid = !formElements.filter(el => el.name === "bot-field")[0].value
  if (!isValid) {
    setSubmitText("It looks like you filled out too many fields! 🤖")
    return
  }
  const userElements = formElements.filter(el => el.name !== "bot-field")
  const requiredElements = userElements.filter(el => !!el.required)
  if (requiredElements.some(el => !el.value)) {
    setSubmitText("Please fill out all fields. 🙏")
    return
  }
  const filledOutElements = userElements
    .filter(el => !!el.value)
    .map(el => encodeURIComponent(el.name) + "=" + encodeURIComponent(el.value))
    .join("&")
  await fetch("/", {
    method: "POST",
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    body: filledOutElements
  }).then(() => {
    setSubmitText("Sent! 🙂")
    userElements.forEach(el => el.disabled = true)
  }).catch(() => {
    setSubmitText("Something went wrong, please refresh the page and try again. 😵")
    userElements.forEach(el => el.disabled = true)
  })
}

function ContactForm (props) {
  const { data } = props
  const { title, hideTitle } = data
  const [ submitText, setSubmitText ] = useState(null)

  return (
    <>
      { hideTitle
      ? null
      : <h2>{title}</h2>
      }
      <div className="wrapper-sm">
        <form className="contact-form" name="contact" method="post" onSubmit={e => handleSubmit(e, setSubmitText)}>
          <input type="hidden" name="form-name" value="contact" />
          <div className="no-display">
            <label htmlFor="bot-field">Don’t fill this out if you’re human</label>
            <input id="bot-field" name="bot-field" />
          </div>
          <div className="grid">
            <div>
              <label className="sr-only" htmlFor="name">Name</label>
              <div className="focus-shadow">
                <input type="text" id="name" name="name" placeholder="Name" autoComplete="name" required />
              </div>
            </div>
            <div>
              <label className="sr-only" htmlFor="email">Email</label>
              <div className="focus-shadow">
                <input type="email" id="email" name="email" placeholder="Email" autoComplete="email" required />
              </div>
            </div>
            <div className="full-width">
              <label className="sr-only" htmlFor="message">Message</label>
              <div className="focus-shadow blur-top-bottom">
                <textarea id="message" name="message" placeholder="Message" required />
              </div>
            </div>
          </div>
          <div className="flex">
            <button className="button" type="submit">Send</button>
            { submitText ? <p>{submitText}</p> : null }
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactForm
