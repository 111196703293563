import "./Socials.css"

function Socials (props) {
  const { data } = props
  const socials = Object
    .keys(data)
    .map(key => data[key])
    .filter(item => item.url?.length)
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <ul className="social-links">
      { socials.map((item, index) => 
        <li key={index}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <i className={item.iconClass} />
            <span className="sr-only">{item.name}</span>
          </a>
        </li>
      ) }
    </ul>
  )
}

export default Socials
