import { trimStrings, encodeMetaImages } from "./helpers"
import sanityClient from "../client"

function getString (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"] {
        text
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getBlockContent (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"] {
        blockContent
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getImage (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"] {
        ...,
        image {
          ...,
          "id": asset._ref,
          "preview": asset-> metadata.lqip,
        }
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getImageWithCaption (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"] {
        image {
          "id": asset._ref,
          "preview": asset-> metadata.lqip,
          "aspectRatio": asset-> metadata.dimensions.aspectRatio,
          hotspot { x, y },
          crop {
            bottom,
            left,
            right,
            top,
          },
          alt
        },
        caption
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getMetadata () {
  return sanityClient
    .fetch(`
      *[_type == "metadata"] {
        favicon,
        titleTemplate,
        homepageName,
        author,
        description,
        image {
          asset-> {
            _id,
            url
          },
          crop {
            top,
            bottom,
            left,
            right
          }
        }
      }
    `)
    .then(data => {
      trimStrings(data)
      encodeMetaImages(data)
      return data
    })
    .catch(error => console.log(`${error}\nCould not fetch data from schema "metadata"`))
}

function getLogo () {
  return sanityClient
    .fetch(`
      *[_type == "logo"] {
        image {
          ...,
          "id": asset._ref,
          "preview": asset-> metadata.lqip
        }
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "logo"`))
}

function getSocials () {
  return sanityClient
    .fetch(`
      *[_type == "socials"] {
        facebook.active == true => {
          facebook {
            ...,
            "name": "Facebook",
            "iconClass": "icon-facebook"
          }
        },
        instagram.active == true => {
          instagram {
            ...,
            "name": "Instagram",
            "iconClass": "icon-instagram"
          }
        },
        linkedin.active == true => {
          linkedin {
            ...,
            "name": "LinkedIn",
            "iconClass": "icon-linkedin"
          }
        },
        linktree.active == true => {
          linktree {
            ...,
            "name": "Linktree",
            "iconClass": "icon-linktree"
          }
        },
        pinterest.active == true => {
          pinterest {
            ...,
            "name": "Pinterest",
            "iconClass": "icon-pinterest"
          }
        },
        tiktok.active == true => {
          tiktok {
            ...,
            "name": "TikTok",
            "iconClass": "icon-tiktok"
          }
        },
        twitter.active == true => {
          twitter {
            ...,
            "name": "Twitter",
            "iconClass": "icon-twitter"
          }
        },
        x.active == true => {
          x {
            ...,
            "name": "X",
            "iconClass": "icon-x"
          }
        }
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "socials"`))
}

function getPages () {
  return sanityClient
    .fetch(`
      *[_type == "pages"] | order(orderRank) {
        ...,
        "slug": slug.current,
        metadata {
          ...,
          image {
            ...,
            asset-> {
              _id,
              url
            }
          }
        },
        "sections": sections[] {
          ...,
          ...select(count(content) > 0 => {
            content[] {
              ...,
              "id": asset._ref,
              "preview": asset-> metadata.lqip
            },
          }),
          ...select(count(leftContent) > 0 => {
            leftContent[] {
              ...,
              "id": asset._ref,
              "preview": asset-> metadata.lqip
            },
          }),
          ...select(count(middleContent) > 0 => {
            middleContent[] {
              ...,
              "id": asset._ref,
              "preview": asset-> metadata.lqip
            },
          }),
          ...select(count(rightContent) > 0 => {
            rightContent[] {
              ...,
              "id": asset._ref,
              "preview": asset-> metadata.lqip
            },
          }),
          ...select(count(items) > 0 => {
            items[] {
              ...,
              image {
                ...,
                "id": asset._ref,
                "preview": asset-> metadata.lqip
              },
              "categories": categories[]-> name
            }
          })
        }
      }
    `)
    .then(data => {
      trimStrings(data)
      data.forEach(page => {
        if (page.metadata) encodeMetaImages([page.metadata])
      })
      return data
    })
    .catch(error => console.log(`${error}\nCould not fetch data from schema "pages"`))
}

export { getString, getBlockContent, getImage, getImageWithCaption, getMetadata, getLogo, getSocials, getPages }
