import { useEffect } from "react"
import "./Loading.css"

function Loading (props) {
  const { fadeOut = false } = props

  useEffect(() => {
    const timer = setTimeout(() => document.getElementById("loading-spinner")?.remove(), 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div id="loading-spinner" className={fadeOut ? "loading-panel fade-out" : "loading-panel"}>
      <span className="loader"></span>
    </div>
  )
}

export default Loading
