import "./Sidebar.css"
import Header from "./Header"
import Footer from "./Footer"

function Sidebar (props) {
  const { author, logo, socials, pages, viewMode } = props

  return (
    <div className="sidebar">
      <Header logo={logo} socials={socials} pages={pages} viewMode={viewMode} />
      <Footer author={author} socials={socials} />
    </div>
  )
}

export default Sidebar
