import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useQuery } from 'react-query'
import { getMetadata, getLogo, getSocials, getPages } from "./utils/api"
import "./App.css"
import Error from "./components/Error"
import Loading from "./components/Loading"
import Redirect from "./components/Redirect"
import Layout from "./layout/Layout"

function App () {
  const { isLoading: isLoadingMetadata, error: errorMetadata, data: dataMetadata } = useQuery("metadata", () => getMetadata())
  const { isLoading: isLoadingLogo, error: errorLogo, data: dataLogo } = useQuery("logo", () => getLogo())
  const { isLoading: isLoadingSocials, error: errorSocials, data: dataSocials } = useQuery("socials", () => getSocials())
  const { isLoading: isLoadingPages, error: errorPages, data: dataPages } = useQuery("pages", () => getPages())

  if (errorMetadata) return <Error message={errorMetadata.message} />
  if (errorLogo) return <Error message={errorLogo.message} />
  if (errorSocials) return <Error message={errorSocials.message} />
  if (errorPages) return <Error message={errorPages.message} />
  if (isLoadingMetadata || isLoadingLogo || isLoadingSocials || isLoadingPages) return <Loading />

  const loadingPanel = document.getElementsByClassName("loading-panel")[0]
  loadingPanel?.classList.add("extended-fade-out")

  const settings = {
    metadata: dataMetadata[0],
    logo: dataLogo[0],
    socials: dataSocials[0]
  }

  const defaultMetadata = {
    titleTemplate: `${settings.metadata.author || "Jess De Vitt"} | {Page}`,
    homepageName: "Home",
    author: "Jess De Vitt",
    description: `${settings.metadata.author || "Jess De Vitt"}'s artist portfolio`
  }

  for (const key in settings.metadata) {
    settings.metadata[key] = settings.metadata[key] || defaultMetadata.current[key]
  }

  const activePages = dataPages.filter(page => page.active)
  
  return (
    <>
      <Loading fadeOut={true} />
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<Redirect url="https://jessdevitt.sanity.studio" />} />
          <Route exact path="/" element={<Layout main="home" settings={settings} pages={activePages} />} />
          { activePages?.map((item, index) =>
            <Route key={index} path={`/${item.slug}`} element={<Layout main="dynamic" settings={settings} pages={activePages} pageData={item} />} />
          ) }
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
