import { useCallback } from "react"
import Content from "../sections/Content"
import Gallery from "../sections/Gallery"
import ContactForm from "../sections/ContactForm"

function Main (props) {
  const { data, viewMode } = props
  const { title, slug, sections, hideTitle } = data
  const activeSections = sections?.filter(section => section.active)

  const sectionMap = useCallback((type, data) => {
    switch (type) {
      case "content":
        return <Content data={data} />
      case "gallery":
        return <Gallery data={data} viewMode={viewMode} />
      case "contactForm":
        return <ContactForm data={data} />
      default:
        return null
    }
  }, [viewMode])

  return (
    <main className={`page--${slug}`}>
      <h1 className={hideTitle ? "sr-only" : ""}>{title}</h1>
      { activeSections?.map((section, index) =>
        <section key={`${slug}-${index}`}>
          { sectionMap(section._type, section) }
        </section>
      ) }
    </main>
  )
}

export default Main
