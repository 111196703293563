// import { useQuery } from "react-query"
// import { PortableText } from "@portabletext/react"
// import { getImageWithCaption } from "../utils/api"
// import { portableTextComponents } from "../utils/helpers"
import "./Home.css"
// import Image from "../components/Image"
import tierra from "../assets/images/Tierra.png"

function Home () {
  // const { isLoading, error, data } = useQuery("homeHeroImage", () => getImageWithCaption("homeHeroImage"))

  // if (isLoading) return

  return (
    <main className="page--home">
      <div className="grid">
        <div className="text-container">
          <div>Portfolio</div>
          <h1>Jess<br /> DeVitt</h1>
          <div>Visual Artist + Educator</div>
        </div>
        <img src={tierra} alt="Tierra" />
        {/* { error
        ? <img src={tierra} alt="Tierra" />
        : null
        }
        { data.map((item, index) =>
          item.caption
          ? <figure key={index}>
              <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
              <figcaption><PortableText value={item.caption} components={portableTextComponents} /></figcaption>
            </figure>
          : <Image key={index} id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
        ) } */}
      </div>
    </main>
  )
}

export default Home
