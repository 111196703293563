import { PortableText } from "@portabletext/react"
import { portableTextComponents } from "../utils/helpers"

function Content (props) {
  const { data } = props
  const { title, width, layout, twoColumnWidths, threeColumnWidths, content, leftContent, middleContent, rightContent, hideTitle } = data

  let className = ""
  switch (width) {
    case "large":
      className += "wrapper-lg"
      break
    case "medium":
      className += "wrapper-md"
      break
    case "small":
      className += "wrapper-sm"
      break
    case "unlimited":
    default:
  }
  switch (layout) {
    case "three-columns":
      className += " three-cols-" + threeColumnWidths
      break
    case "two-columns":
      className += " two-cols-" + twoColumnWidths
      break
    case "one-column":
    default:
  }
  className = className.trim()

  return (
    <>
      { hideTitle
      ? null
      : <h2>{title}</h2>
      }
      <div className={className}>
        { content
        ? <div className="flow">
            { content.map((col, colIndex) =>
              <PortableText key={colIndex} value={col} components={portableTextComponents} />
            ) }
          </div>
        : null
        }
        { leftContent
        ? <div className="flow">
            { leftContent.map((col, colIndex) =>
              <PortableText key={colIndex} value={col} components={portableTextComponents} />
            ) }
          </div>
        : null
        }
        { middleContent
        ? <div className="flow">
            { middleContent.map((col, colIndex) =>
              <PortableText key={colIndex} value={col} components={portableTextComponents} />
            ) }
          </div>
        : null
        }
        { rightContent
        ? <div className="flow">
            { rightContent.map((col, colIndex) =>
              <PortableText key={colIndex} value={col} components={portableTextComponents} />
            ) }
          </div>
        : null
        }
      </div>
    </>
  )
}

export default Content
