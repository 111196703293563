import { useState, useRef, useCallback, useLayoutEffect, useEffect } from "react"
import { PortableText } from "@portabletext/react"
import { portableTextComponents } from "../utils/helpers"
import "./Gallery.css"
import Image from "../components/Image"
import Modal from "../components/Modal"

const SMALL_HEADER_HEIGHT = 80
const LARGE_HEADER_HEIGHT = 110
const MOBILE_MAIN_PADDING_TOP = 15

function resizeGridItem (item) {
  const img = item.querySelector("img")
  const grid = item.closest(".grid-container")
  const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")) || 0
  const rowGap = parseInt(window.getComputedStyle(document.body).getPropertyValue("--masonry-grid-gap")) || 0
  const itemHeight = item.offsetWidth * img.getAttribute("height") / img.getAttribute("width")
  const rowSpan = Math.ceil((itemHeight / rowHeight) + rowGap)
  item.style.gridRowEnd = "span " + rowSpan
}

function resizeGridItems () {
  const gridItems = document.querySelectorAll(".grid-container .grid-item")
  gridItems.forEach(item => { 
    resizeGridItem(item)
  })
}

function desizeTiles () {
  const tiles = document.querySelectorAll(".tile-container .tile")
  tiles.forEach(item => item.style.gridRowEnd = null)
}

function getPrevTile () {
  const headerIsExpanded = !document.querySelector("header.small")
  const containerTop = headerIsExpanded ? LARGE_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP : SMALL_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP
  const tiles = Array.from(document.querySelectorAll(".tile-container .tile"))
  let prevTile, prevTileTop
  tiles.forEach(tile => {
    const top = Math.round(tile.getBoundingClientRect().top)
    if (top < containerTop && (top > prevTileTop || !prevTileTop)) {
      prevTile = tile
      prevTileTop = top
    }
  })
  return prevTile
}

function getNextTile () {
  const headerIsExpanded = !document.querySelector("header.small")
  const containerTop = headerIsExpanded ? LARGE_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP : SMALL_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP
  const tiles = Array.from(document.querySelectorAll(".tile-container .tile"))
  let nextTile, nextTileTop
  tiles.forEach(tile => {
    const top = Math.round(tile.getBoundingClientRect().top)
    if (top > containerTop && (top < nextTileTop || !nextTileTop)) {
      nextTile = tile
      nextTileTop = top
    }
  })
  return nextTile
}

function toPrev () {
  const prevTile = getPrevTile()
  if (prevTile) {
    const containerTop = SMALL_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP
    const target = Math.round(prevTile.offsetTop) - containerTop
    window.scroll({top: target, behavior: "smooth"})
  }
}

function toNext () {
  const nextTile = getNextTile()
  if (nextTile) {
    const atTop = !document.getElementsByTagName("header")[0].classList.contains("small")
    const containerTop = atTop ? LARGE_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP : SMALL_HEADER_HEIGHT + MOBILE_MAIN_PADDING_TOP
    const target = nextTile.offsetTop - containerTop
    window.scroll({top: target, behavior: "smooth"})
  }
}

function toTop () {
  window.scroll({top: 0, behavior: "smooth"})
}

function toBottom () {
  window.scroll({top: document.body.scrollHeight, behavior: "smooth"})
}

function handleKeyPress (event) {
  if (event.which === 13 && event.target.tagName === "INPUT" && event.target.type === "checkbox") {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "checked").set
    nativeInputValueSetter.call(event.target, !event.target.checked)
    const clickEvent = new Event("click", {bubbles: true})
    event.target.dispatchEvent(clickEvent)
  }
}

function Gallery (props) {
  const { data, viewMode } = props
  const { title, layout, items, showCategories, hideTitle } = data
  const [ filteredItems, setFilteredItems ] = useState(null)
  const [ modalIndex, setModalIndex ] = useState(-1)
  const categories = useRef([])
  const selectedCategories = useRef([])
  const activeItems = useRef(items?.filter(item => item.active))

  const openModal = useCallback(index => {
    setModalIndex(index)
    document.body.classList.add("no-scroll")
  }, [])

  const closeModal = useCallback(() => {
    setModalIndex(-1)
    document.body.classList.remove("no-scroll")
  }, [])

  const handleChange = useCallback((value, isSelected) => {
    const selectedCategoriesSet = new Set(selectedCategories.current)
    if (isSelected) selectedCategoriesSet.add(value)
    else selectedCategoriesSet.delete(value)
    selectedCategories.current = Array.from(selectedCategoriesSet)
    setFilteredItems(selectedCategories.current.length ? activeItems.current.filter(item => item.categories?.some(category => selectedCategories.current.includes(category))) : activeItems.current)
  }, [activeItems])

  useLayoutEffect(() => {
    if (["phone", "tablet-portrait"].includes(viewMode)) {
      closeModal()
      desizeTiles()
    }
  }, [viewMode, closeModal])

  useLayoutEffect (() => {
    resizeGridItems()
    window.addEventListener("resize", resizeGridItems)
    return () => window.removeEventListener("resize", resizeGridItems)
  }, [filteredItems])

  useEffect(() => {
    if (activeItems.current) {
      setFilteredItems(activeItems.current)
      const categoriesSet = new Set()
          activeItems.current.forEach(item => {
            item.categories?.forEach(category => categoriesSet.add(category))
          })
      categories.current = Array.from(categoriesSet)
    }
  }, [activeItems])

  useEffect(() => {
    document.body.addEventListener("keypress", handleKeyPress)
    return () => document.body.removeEventListener("keypress", handleKeyPress)
  }, [])

  return (
    <>
      { hideTitle
      ? null
      : <h2>{title}</h2>
      }
      { showCategories
      ? <fieldset className="categories" aria-controls="gallery">
          <legend className="sr-only">Filter by category</legend>
          { categories.current.map((category, index) =>
            <label key={index}>
              <input className="sr-only" type="checkbox" value={category} onChange={e => handleChange(e.target.value, e.target.checked)} checked={selectedCategories.current.includes(category)} />
              {category}
            </label>
          ) }
        </fieldset>
      : null
      }
      <div className={`${layout}-gallery`}>
        { viewMode === "phone"
        ? <>
            <div className="tile-container">
              { filteredItems?.map((item, index) =>
                <div key={index} className="tile">
                  <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
                  <div className="text-container">
                    { item.title ? <h2>{item.title}</h2> : null }
                    <div>
                      { item.date ? <span><strong>Year:</strong> {item.date}</span> : null }
                      { item.medium ? <span><strong>Medium:</strong> {item.medium}</span> : null }
                    </div>
                    { item.description ? <PortableText value={item.description} components={portableTextComponents} /> : null }
                  </div>
                </div>
              ) }
            </div>
            { filteredItems?.length > 1
            ? <div className="tile-nav">
                <button id="to-previous-tile" className="tile-button" type="button" onClick={toPrev}>
                  <i className="icon-arrow-up" />
                  <span className="sr-only">Previous tile</span>
                </button>
                <button id="to-first-tile" className="tile-button" type="button" onClick={toTop}>
                  <i className="icon-double-arrow-up" />
                  <span className="sr-only">First tile</span>
                </button>
                <button id="to-last-tile" className="tile-button" type="button" onClick={toBottom}>
                  <i className="icon-double-arrow-down" />
                  <span className="sr-only">Last tile</span>
                </button>
                <button id="to-next-tile" className="tile-button" type="button" onClick={toNext}>
                  <i className="icon-arrow-down" />
                  <span className="sr-only">Next tile</span>
                </button>
              </div>
            : null
            }
          </>
        : <div className="grid-container">
            { filteredItems?.map((item, index) =>
              <div key={index} className="grid-item">
                <button className="image-button" type="button" onClick={() => openModal(index)}>
                  <span className="sr-only">View image</span>
                  <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
                </button>
              </div>
            ) }
          </div>
        }
      </div>
      { (viewMode !== "phone" && modalIndex >= 0) ? <Modal data={filteredItems} initialIndex={modalIndex} closeModal={closeModal} /> : null }
    </>
  )
}

export default Gallery
