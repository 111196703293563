import { useState, useCallback, useEffect, useLayoutEffect } from "react"
import Head from "../components/Head"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import Footer from "../components/Footer"
import Home from "./Home"
import Main from "./Main"

function Layout (props) {
  const { main, settings, pages, pageData } = props
  const { metadata: siteMetadata, logo, socials } = settings
  const [ viewMode, setViewMode ] = useState(
    window.innerWidth < 600 ? "phone" : 
    window.innerWidth < 900 ? "tablet-portrait" : 
    window.innerWidth < 1200 ? "tablet-landscape" : 
    window.innerWidth < 1500 ? "small-desktop" : 
    window.innerWidth < 1800 ? "desktop" : 
    "big-desktop"
  )

  // instantiate a page metadate object as a clone of the site metadata
  const metadata = structuredClone(siteMetadata)

  // replace site metadata with page-specific metadata if it is available
  for (const key in pageData?.metadata) {
    metadata[key] = pageData.metadata[key] || metadata[key]
  }

  // set the name and title of the current page
  switch (main) {
    case "home":
      metadata.name = metadata.homepageName
      break
    default:
      metadata.name = pageData.metadata?.name || pageData.title
  }
  metadata.title = metadata.titleTemplate.replaceAll("{Page}", metadata.name)

  // console.log("metadata ", metadata)
  // console.log("logo ", logo)
  // console.log("socials ", socials)
  // console.log("pages ", pages)
  // console.log("pageData ", pageData)

  const handleResize = useCallback(() => {
    switch (true) {
      case window.innerWidth < 600:
        if (viewMode !== "phone") setViewMode("phone")
        break
      case window.innerWidth < 900:
        if (viewMode !== "tablet-portrait") setViewMode("tablet-portrait")
        break
      case window.innerWidth < 1200:
        if (viewMode !== "tablet-landscape") setViewMode("tablet-landscape")
        break
      case window.innerWidth < 1500:
        if (viewMode !== "small-desktop") setViewMode("small-desktop")
        break
      case window.innerWidth < 1800:
        if (viewMode !== "desktop") setViewMode("desktop")
        break
      default:
        if (viewMode !== "big-desktop") setViewMode("big-desktop")
    }
  }, [viewMode])

  useLayoutEffect(() => {
    const main = document.getElementsByTagName("main")[0]
    const footer = document.querySelector("main + footer")
    main?.classList.add("delayed-fade-in")
    footer?.classList.add("delayed-fade-in")
    const timer = setTimeout(() => {
      main?.classList.remove("delayed-fade-in")
      footer?.classList.remove("delayed-fade-in")
    }, 1500)
    return () => {
      main?.classList.remove("delayed-fade-in")
      footer?.classList.remove("delayed-fade-in")
      clearTimeout(timer)
    }
  }, [main, pageData])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])

  const pageMap = {
    home: <Home />,
    dynamic: <Main data={pageData} viewMode={viewMode} />
  }

  return (
    <>
      <Head metadata={metadata} />
      <div id="page-top"></div>
      { ["desktop", "big-desktop"].includes(viewMode) ? <Sidebar author={metadata.author} logo={logo} socials={socials} pages={pages} viewMode={viewMode} /> : <Header author={metadata.author} logo={logo} socials={socials} pages={pages} viewMode={viewMode} /> }
      { pageMap[main] || <Home /> }
      { ["desktop", "big-desktop"].includes(viewMode) ? null : <Footer author={metadata.author} socials={socials} /> }
    </>
  )
}

export default Layout
